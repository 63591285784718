import React from "react"
import { Link } from "gatsby"
import posed from "react-pose"
import styles from "../styles/summary.module.css"

const SpeechIcon = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.05,
  },
  press: {
    scale: 0.95,
  },
})

class Summary extends React.Component {
  render() {
    return (
      <div className={styles.summary}>
        <Link to={this.props.link}>
          <SpeechIcon className={styles.picture}>
            <img
              className={styles.pictureImage}
              src={this.props.image}
              alt="Icon"
              height="100"
              width="100"
            />
          </SpeechIcon>
        </Link>
        <div className={styles.info}>
          <h4>
            {this.props.title} | {this.props.date}
          </h4>
          <div
            className={styles.desc}
            dangerouslySetInnerHTML={{ __html: this.props.description }}
          />
          <div className={styles.btns}>
            <Link to={this.props.link}>
              <button className={styles.button}>Watch</button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Summary
